import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CreateSportsStreamSubscriptionParams,
  sportsStreamApi,
  SportsStreamSubscriptionEvent,
  GetStreamTeamsResponse
} from '../../api/sports-stream-api';
import { Button, Form, FormControl, FormGroup, FormLabel, Spinner, Table } from 'react-bootstrap';
import { TemplateBuilder } from './template-builder';
import { TextField } from '../shared/TextField';
import { toast } from 'react-toastify';

enum SportRadarSubscriptionEventType {
  'Game Start' = 'opentip',
  'Score Update' = 'score_update',
  'Close Game' = 'close_game',
  'Final Score' = 'final_score',
  'Overtime' = 'overtime',
  'Game Reminder' = 'game_remainder'
}

const eventFieldsDefaultValues = {
  segmentId: '',
  templateJson: JSON.stringify({})
};

const streamEventsDefaultState: { [key: string]: SportsStreamSubscriptionEvent & { enabled: boolean } } = {};
Object.keys(SportRadarSubscriptionEventType).map((event) => {
  streamEventsDefaultState[event] = { ...eventFieldsDefaultValues, enabled: false, type: event };
});

const CreateStreamSubscription = () => {
  const [loading, setLoading] = useState(false);
  const [streamSubscription, setStreamSubscription] = useState<CreateSportsStreamSubscriptionParams>();
  const [streamEvents, setStreamEvents] = useState(streamEventsDefaultState);
  const [showTemplateBuilder, setShowTemplateBuilder] = useState(false);
  const [currentEventTemplate, setCurrentEventTemplate] = useState<string>();
  const [streamTeams, setStreamTeams] = useState<GetStreamTeamsResponse>();
  const history = useHistory();

  const getStreamTeams = async (sportRadarApiKey: string): Promise<GetStreamTeamsResponse> => {
    return await sportsStreamApi.getTeams({ sportRadarApiKey });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await sportsStreamApi.createSubscription(streamSubscription);
    } catch (e) {
      toast(`creating stream subscription failed: ${e.message}`, {
        position: 'bottom-right',
        autoClose: 5000,
        type: 'error',
        hideProgressBar: true,
        closeOnClick: false,
        closeButton: false,
        pauseOnHover: false,
        draggable: false
      });
    }
    setLoading(false);
    history.push('/stream/subscriptions');
  };
  const onChangeEventCheckbox = (eventType: string) => (event: any) => {
    setStreamEvents({ ...streamEvents, [eventType]: { ...streamEvents[eventType], enabled: event.target.checked } });
  };

  const onChangeEventTemplateJson = (eventType: string) => (event: any) => {
    setStreamEvents({ ...streamEvents, [eventType]: { ...streamEvents[eventType], templateJson: event } });
  };

  const onChangeEventField = (eventType: string, field: string) => (event: any) => {
    setStreamEvents({ ...streamEvents, [eventType]: { ...streamEvents[eventType], [field]: event.target.value } });
  };

  const onChangeStreamSubscriptionField = (field: string) => (event: any) => {
    setStreamSubscription({ ...streamSubscription, [field]: event.target.value });
  };

  const onSportRadarApiKeyBlur = () => (event: React.FocusEvent<HTMLInputElement>) => {
    setLoading(true);
    (async () => {
      try {
        if (streamSubscription?.sportRadarApiKey) {
          const teams = await getStreamTeams(streamSubscription?.sportRadarApiKey);
          setStreamTeams(teams);
        }
      } catch (error) {
        toast('Fetching Teams failed', {
          position: 'bottom-right',
          autoClose: 5000,
          type: 'error',
          hideProgressBar: true,
          closeOnClick: false,
          closeButton: false,
          pauseOnHover: false,
          draggable: false
        });
      }
      setLoading(false);
    })();
  };

  const handleOpenModal = (eventType: string) => {
    setCurrentEventTemplate(eventType);
    setShowTemplateBuilder(true);
  };

  const handleCloseModal = () => {
    setCurrentEventTemplate(null);
    setShowTemplateBuilder(false);
  };

  useEffect(() => {
    const enabledEvents = Object.entries(streamEvents)
      .filter(([eventName, eventValues]) => eventValues.enabled)
      .map(([eventName, eventValues]) => {
        const { enabled, templateJson, type, ...newEventValue } = eventValues;
        return {
          ...newEventValue,
          templateJson: JSON.parse(templateJson),
          type: SportRadarSubscriptionEventType[type as keyof typeof SportRadarSubscriptionEventType]
        };
      });
    setStreamSubscription({ ...streamSubscription, events: enabledEvents });
  }, [streamEvents]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <FormLabel>Sportradar Api Key</FormLabel>
          <FormControl
            type="sportRadarApiKey"
            required
            onChange={onChangeStreamSubscriptionField('sportRadarApiKey')}
            onBlur={onSportRadarApiKeyBlur()}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Tenant Id</FormLabel>
          <FormControl type="tenantId" required onChange={onChangeStreamSubscriptionField('tenantId')} />
        </FormGroup>
        <FormGroup>
          <FormLabel>Sport</FormLabel>
          <FormControl as="select" onChange={onChangeStreamSubscriptionField('sport')}>
            <option value={''}>Choose Sport</option>
            <option value={'wnba'}>WNBA</option>
          </FormControl>
        </FormGroup>
        {streamSubscription?.sportRadarApiKey && streamTeams?.teams?.length > 0 && (
          <>
            <FormGroup>
              <FormLabel>Platform Api Key</FormLabel>
              <FormControl type="apiKey" required onChange={onChangeStreamSubscriptionField('apiKey')} />
            </FormGroup>
            <FormGroup>
              <FormLabel>Platform Api Key Secret</FormLabel>
              <FormControl type="apiKeySecret" required onChange={onChangeStreamSubscriptionField('apiKeySecret')} />
            </FormGroup>
            <FormGroup>
              <FormLabel>Team</FormLabel>
              <FormControl as="select" required onChange={onChangeStreamSubscriptionField('teamId')}>
                <option value={''}>Choose Team</option>
                {streamTeams ? (
                  streamTeams.teams.map((team) => (
                    <option value={team.id} key={team.id}>
                      {team.name}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </FormControl>
              {/*<FormControl type="teamId" required onChange={onChangeStreamSubscriptionField('teamId')} />*/}
            </FormGroup>
            <FormGroup>
              <FormLabel>Season Id (Optional)</FormLabel>
              <FormControl type="seasonId" onChange={onChangeStreamSubscriptionField('seasonId')} />
            </FormGroup>
            <FormGroup>
              <h4>Events</h4>
              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    {Object.keys(SportRadarSubscriptionEventType).map((event, i) => (
                      <th key={i}>{event}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {Object.keys(SportRadarSubscriptionEventType).map((event, i) => (
                      <td key={i}>
                        <FormGroup>
                          <Form.Check
                            type="switch"
                            label="Enable"
                            id={`${event}-switch`}
                            onChange={onChangeEventCheckbox(event)}
                            checked={streamEvents[event].enabled}
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormLabel>Segment Id</FormLabel>
                          <FormControl
                            type={`${event}.segmentId`}
                            onChange={onChangeEventField(event, 'segmentId')}
                            value={streamEvents[event].segmentId}
                            required={streamEvents[event].enabled}
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormLabel>Video Template Url</FormLabel>
                          <FormControl
                            type={`${event}.videoTemplateUrl`}
                            onChange={onChangeEventField(event, 'videoTemplateUrl')}
                            value={streamEvents[event].videoTemplateUrl}
                            required={
                              streamEvents[event].enabled &&
                              JSON.parse(streamEvents[event].templateJson)?.pushMessage?.type === 'VIDEO'
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormLabel>Target Url</FormLabel>
                          <FormControl
                            type={`${event}.targetUrl`}
                            onChange={onChangeEventField(event, 'targetUrl')}
                            value={streamEvents[event].targetUrl}
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormLabel>
                            Template JSON -{' '}
                            <Button type="button" variant="secondary" size="sm" onClick={() => handleOpenModal(event)}>
                              Edit
                            </Button>
                          </FormLabel>
                          <TextField
                            value={
                              streamEvents[event].templateJson ? JSON.stringify(streamEvents[event].templateJson) : null
                            }
                            onChange={() => null}
                            name={`${event}-templateJson`}
                          />
                        </FormGroup>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
              <TemplateBuilder
                isOpen={showTemplateBuilder}
                value={streamEvents[currentEventTemplate]?.templateJson}
                onChange={onChangeEventTemplateJson(currentEventTemplate)}
                onClose={() => handleCloseModal()}
                eventType={currentEventTemplate}
              />
            </FormGroup>
          </>
        )}

        <Button type="submit" disabled={loading || streamSubscription?.events?.length === 0}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
        </Button>
      </Form>
    </>
  );
};

export default CreateStreamSubscription;
